import { DocumentAction } from "@analysis/types/AnalysisDetails/Webapp/DocumentAction";
import { SignatureAction } from "@analysis/types/AnalysisDetails/Webapp/SignatureAction";
import { SignatureActionUpdate } from "@analysis/types/AnalysisDetails/Webapp/SignatureActionUpdate";

export const isDocumentAction = 
(action: DocumentAction | SignatureAction): action is DocumentAction => {
  return "document_id" in action;
};

export const isSignatureActionUpdate = (
  update: DocumentAction | SignatureActionUpdate): update is SignatureActionUpdate => {
  return "raw_filename" in update && "raw_filepath" in update;
};
